import React from 'react';
import PageLayout from '../components/PageLayout';
import ThemesManager from '../components/Themes/ThemesManager';

function ThemeDetectorShopifyThemes() {
  return (
    <PageLayout>
      <ThemesManager cms="Shopify" />
    </PageLayout>
  );
}

export default ThemeDetectorShopifyThemes;
