import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button,
  Divider, Form, Input, InputNumber, message, Table, Tag,
} from 'antd';
import {
  collection, getDocs, getFirestore, limit, orderBy, query, QueryConstraint, Timestamp, where,
} from 'firebase/firestore';
import { ReloadOutlined } from '@ant-design/icons';
import PageLayout from '../components/PageLayout';

type CMS = 'WordPress' | 'Shopify' | 'BigCommerce' | 'Drupal' | 'Joomla' | 'Ghost' | 'Blogger' | 'Tumblr' | 'Squarespace' | 'Big Cartel' | 'Magento' | 'UNKNOWN';

interface Theme {
  name: string,
  originalName: string | null,
  url: string | null,
}

interface ThemeSearchResult {
  id: string,
  detector: CMS,
  cms: CMS,
  theme: Theme | null,
  url: string,
  isWooCommerceStore: boolean | undefined,
  customizationScore: number | undefined,
  createdAt: Timestamp;
}

function ThemeDetectorStats() {
  const [loading, setLoading] = useState(true);
  const [searches, setSearches] = useState<ThemeSearchResult[]>([]);
  const [queryLimit, setQueryLimit] = useState(100);
  const [detector, setDetector] = useState('');
  const [cms, setCms] = useState('');
  const [themeName, setThemeName] = useState('');
  const [siteUrl, setSiteUrl] = useState('');

  const columns = useMemo(() => [
    {
      title: 'Theme Detector',
      dataIndex: 'detector',
      key: 'detector',
    },
    {
      title: 'CMS',
      dataIndex: 'cms',
      key: 'cms',
    },
    {
      title: 'Site URL',
      dataIndex: 'url',
      key: 'url',
      render: (url: string) => <a href={url} target="_blank" rel="noreferrer">{url}</a>,
      ellipsis: true,
    },
    {
      title: 'Theme',
      dataIndex: 'theme',
      key: 'theme.name',
      render: (theme: Theme | null) => {
        if (!theme) {
          return <Tag color="orange">Not Found</Tag>;
        }
        return theme.url
          ? <a href={theme.url} target="_blank" rel="noreferrer">{theme.name}</a>
          : theme.name;
      },
    },
    {
      title: 'Is WooCommerce store?',
      dataIndex: 'isWooCommerceStore',
      key: 'isWooCommerceStore',
    },
  ], []);

  const fetchSearches = useCallback(() => {
    setLoading(true);
    const firestore = getFirestore();
    const wheres: QueryConstraint[] = [];
    if (detector) {
      wheres.push(where('detector', '==', detector));
    }
    if (cms) {
      wheres.push(where('cms', '==', cms));
    }
    if (themeName) {
      wheres.push(where('theme.name', '==', themeName));
    }
    if (siteUrl) {
      wheres.push(where('url', '==', siteUrl));
    }
    const q = query(
      collection(firestore, 'theme-detector-search-results'),
      ...wheres,
      orderBy('createdAt', 'desc'),
      limit(queryLimit || 1000),
    );
    getDocs(q).then((querySnap) => {
      const newSearches: ThemeSearchResult[] = [];
      querySnap.forEach((document) => {
        newSearches.push({ ...document.data() as ThemeSearchResult, id: document.id });
      });
      setSearches(newSearches);
    }).catch((error) => {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! Something went wrong while fetching data from firebase.');
    }).finally(() => {
      setLoading(false);
    });
  }, [cms, detector, queryLimit, siteUrl, themeName]);

  // fetch searches
  useEffect(() => {
    fetchSearches();
  }, [fetchSearches]);

  return (
    <PageLayout>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Form layout="inline">
          <Form.Item label="Limit">
            <InputNumber
              type="number"
              value={queryLimit}
              onChange={setQueryLimit}
            />
          </Form.Item>
          <Form.Item label="Detector">
            <Input
              value={detector}
              placeholder="Shopify"
              onChange={(event) => setDetector(event.currentTarget.value)}
            />
          </Form.Item>
          <Form.Item label="CMS">
            <Input
              value={cms}
              placeholder="Shopify"
              onChange={(event) => setCms(event.currentTarget.value)}
            />
          </Form.Item>
          <Form.Item label="Theme Name">
            <Input
              value={themeName}
              placeholder="Dawn"
              onChange={(event) => setThemeName(event.currentTarget.value)}
            />
          </Form.Item>
          <Form.Item label="Site URL">
            <Input
              value={siteUrl}
              placeholder="https://example.com"
              onChange={(event) => setSiteUrl(event.currentTarget.value)}
            />
          </Form.Item>
        </Form>
        <Button
          type="primary"
          icon={<ReloadOutlined />}
          onClick={fetchSearches}
          loading={loading}
        >
          Refresh
        </Button>
      </div>

      <Divider />

      <div style={{ overflowX: 'auto' }}>
        <Table
          loading={loading}
          dataSource={searches}
          columns={columns}
          rowKey={(searchItem) => searchItem.id}
        />
      </div>
    </PageLayout>
  );
}

export default ThemeDetectorStats;
