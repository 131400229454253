import React, { useEffect, useState } from 'react';
import { message, Space, Tabs } from 'antd';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import ThemesTable from './ThemesTable';
import { SavedTheme } from '../../types';
import AutoIndexBtn from './AutoIndexBtn';
import CSVExportBtn from '../CSVExportBtn';
import CSVImportBtn from '../CSVImportBtn';
import DocDeleteBtn from '../DocDeleteBtn';

const { TabPane } = Tabs;
const csvColumns = ['name', 'url', 'marketplace'];

interface Props {
  cms: 'Shopify' | 'WordPress',
}

function ThemesManager({ cms }: Props) {
  const [loading, setLoading] = useState(true);
  const [manuallyManagedThemes, setManuallyManagedThemes] = useState<SavedTheme[]>([]);

  const firestore = getFirestore();
  const collectionName = cms === 'Shopify' ? 'shopify-themes' : 'wordpress-themes';
  const autoIndexedThemesDocRef = doc(firestore, collectionName, 'auto-indexed');
  const manuallyManagedThemesDocRef = doc(firestore, collectionName, 'manually-managed');

  // fetch manually managed themes on load
  useEffect(() => {
    const unsubscribe = onSnapshot(manuallyManagedThemesDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data() as { themes: SavedTheme[] } | undefined;
        setManuallyManagedThemes(data?.themes || []);
      } else {
        setManuallyManagedThemes([]);
      }
      setLoading(false);
    }, (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      setLoading(false);
      message.error('Oops, something went wrong while fetching data from firebase.');
    });
    return unsubscribe;
  }, [manuallyManagedThemesDocRef]);

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Auto Indexed" key="1">
        <AutoIndexBtn cms={cms} />
        <p />
        <ThemesTable docRef={autoIndexedThemesDocRef} />
      </TabPane>
      <TabPane tab="Manually Managed" key="2">
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Space>
            <CSVImportBtn
              itemName="theme"
              docRef={manuallyManagedThemesDocRef}
              columns={csvColumns}
              uniqueColumn="name"
              existingItems={manuallyManagedThemes}
              sampleData={[
                { name: 'Dawn', url: 'https://example.com/', marketplace: 'Shopify' },
                { name: 'GoStore', url: 'https://themeforest.net/item/gostore-elementor-woocommerce-wordpress-theme/31294353', marketplace: 'ThemeForest' },
                { name: 'Underscores', url: 'https://underscores.me/', marketplace: undefined },
              ]}
              fileFieldHelpText={`The CSV file should have three columns: "${csvColumns.join(', ')}" in the exact same order.
              The marketplace column is optional and can be left empty.`}
              itemValidator={(item: SavedTheme) => (
                typeof item.name === 'string'
                && item.name.length > 0
                && typeof item.url === 'string'
                && (typeof item.marketplace === 'string' || typeof item.marketplace === 'undefined')
              )}
              formalDocData={(items: SavedTheme[]) => ({
                themes: items,
              })}
            />
            <CSVExportBtn
              data={manuallyManagedThemes}
              columns={csvColumns}
              exportFilename={`${cms.toLowerCase()}-theme-export-${new Date().toDateString()}.csv`}
              disabled={loading}
            />
          </Space>
          <DocDeleteBtn
            docRef={manuallyManagedThemesDocRef}
            buttonText="Delete All"
            confirmMessage="Are you sure you want to delete all themes?"
            successMessage="Successfully deleted all themes."
            errorMessage="Oops, something went wrong while deleting themes. Pls try again."
          />
        </div>
        <p />
        <ThemesTable docRef={manuallyManagedThemesDocRef} />
      </TabPane>
    </Tabs>
  );
}

export default ThemesManager;
