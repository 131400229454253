import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button,
  Col,
  Divider, Form, Input, InputNumber, message, Row, Spin, Switch, Table, TableColumnsType, Tag,
} from 'antd';
import {
  collection, getDocs, getFirestore, limit, orderBy, query, QueryConstraint, Timestamp, where,
} from 'firebase/firestore';
import { ReloadOutlined } from '@ant-design/icons';
import PageLayout from '../components/PageLayout';
import LineChart, { LineData } from '../components/LineChart';
import PieChart, { PieData } from '../components/PieChart';

interface Click {
  id: string;
  linkId: string;
  ip?: string;
  userAgent: string;
  isBot?: boolean,
  referrer?: string;
  createdAt: Timestamp;
  isDuplicate: boolean,
}

export interface PrettyLink {
  uid: string;
  id: string;
  slug: string;
  url: string;
  redirectType: string;
  categories: string[];
  tags: string[];
  nofollow: boolean;
  paramForwarding: boolean;
  sponsored: boolean;
  trackMe: boolean;
  rotations: Array<{
    url: string,
    weight: number,
  }>,
  createdAt: string;
  updatedAt: string;
}

function getUniqueClickHash(linkId: string, ip: string, createdAtSeconds: number) {
  return `${linkId}|${ip}|${Math.round(createdAtSeconds / 5) * 5}`;
}

function RedirectEngineClicks() {
  const [loading, setLoading] = useState(true);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [links, setLinks] = useState<PrettyLink[]>([]);
  const [clicks, setClicks] = useState<Click[]>([]);
  const [queryLimit, setQueryLimit] = useState(1000);
  const [userIp, setUserIp] = useState('');
  const [prettyLinkSlug, setPrettyLinkSlug] = useState('');
  const [referrer, setReferrer] = useState('');
  const [prettyLink, setPrettyLink] = useState<PrettyLink>();

  const firestore = useMemo(() => getFirestore(), []);

  const columns: TableColumnsType<Click> = useMemo(() => [
    {
      title: 'Pretty Link',
      dataIndex: 'linkId',
      key: 'link',
      render: (linkId: string) => links.find(({ uid }) => uid === linkId)?.slug || '',
    },
    {
      title: 'Redirect URL',
      dataIndex: 'linkId',
      key: 'url',
      render: (linkId: string) => links.find(({ uid }) => uid === linkId)?.url || '',
      ellipsis: true,
    },
    {
      title: 'Advertiser',
      dataIndex: 'linkId',
      key: 'categories',
      render: (linkId: string) => links.find(({ uid }) => uid === linkId)?.categories[0] || '',
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
    },
    {
      title: 'UA',
      dataIndex: 'userAgent',
      key: 'userAgent',
      responsive: ['xxl'],
      ellipsis: true,
    },
    {
      title: 'Referrer',
      dataIndex: 'referrer',
      key: 'referrer',
      ellipsis: true,
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: Timestamp) => createdAt.toDate().toLocaleString(),
    },
    {
      title: 'Duplicate Click?',
      dataIndex: 'isDuplicate',
      key: 'isDuplicate',
      filters: [{
        text: 'Yes',
        value: true,
      }, {
        text: 'No',
        value: false,
      }],
      onFilter: (value, click) => click.isDuplicate === value,
      render: (isDuplicate: boolean) => (isDuplicate ? <Tag color="orange">Duplicate</Tag> : ''),
    },
    {
      title: 'Is Bot?',
      dataIndex: 'isBot',
      key: 'isBot',
      filters: [{
        text: 'Yes',
        value: true,
      }, {
        text: 'No',
        value: false,
      }],
      onFilter: (value, click) => (click.isBot || false) === value,
      render: (isBot: boolean) => (isBot ? <Tag color="error">Bot</Tag> : ''),
    },
  ], [links]);

  useEffect(() => {
    setPrettyLink(links.find(({ slug }) => slug === prettyLinkSlug));
  }, [links, prettyLinkSlug]);

  // fetch links
  useEffect(() => {
    const q = query(collection(firestore, 'pretty-links'));
    getDocs(q).then((querySnap) => {
      const newLinks: PrettyLink[] = [];
      querySnap.forEach((document) => {
        newLinks.push({ ...document.data() as PrettyLink, uid: document.id });
      });
      setLinks(newLinks);
    }).catch((error) => {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! Something went wrong while fetching data from firebase.');
    }).finally(() => {
      setLoading(false);
    });
  }, [firestore]);

  const fetchClicks = useCallback(() => {
    setLoading(true);
    const wheres: QueryConstraint[] = [];
    if (userIp) {
      wheres.push(where('ip', '==', userIp));
    }
    if (prettyLink) {
      wheres.push(where('linkId', '==', prettyLink.id));
    }
    if (referrer) {
      wheres.push(where('referrer', '==', referrer));
    }
    const q = query(
      collection(firestore, 'pretty-link-clicks'),
      ...wheres,
      orderBy('createdAt', 'desc'),
      limit(queryLimit || 1000),
    );
    getDocs(q).then((querySnap) => {
      const newClicks: Click[] = [];
      const clickHashes = new Set<string>();
      querySnap.forEach((document) => {
        const data = document.data() as Omit<Click, 'id' | 'isDuplicate'>;
        const clickHash = getUniqueClickHash(data.linkId, data.ip || '', data.createdAt.seconds);
        newClicks.push({
          ...data,
          isDuplicate: clickHashes.has(clickHash),
          id: document.id,
        });
        clickHashes.add(clickHash);
      });
      setClicks(newClicks);
    }).catch((error) => {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! Something went wrong while fetching data from firebase.');
    }).finally(() => {
      setLoading(false);
    });
  }, [firestore, prettyLink, queryLimit, referrer, userIp]);

  // initially fetch click data
  useEffect(() => {
    fetchClicks();
  }, [fetchClicks]);

  // then fetch click data every minutes unless autoRefresh is off
  useEffect(() => {
    const timerId = setInterval(() => {
      if (autoRefresh && document.hasFocus()) {
        fetchClicks();
      }
    }, 60 * 1000); // every minute

    return () => {
      clearInterval(timerId);
    };
  }, [autoRefresh, fetchClicks]);

  const lineData: LineData[] = useMemo(() => {
    const obj: {
      [key: string]: {
        all: number,
        bot: number,
        duplicate: number,
        rest: number,
      }
    } = {};

    clicks.forEach((click) => {
      const time = new Date(Math.round(click.createdAt.seconds / 60) * 60 * 1000).toLocaleString();
      if (!obj[time]) {
        obj[time] = {
          all: 0,
          bot: 0,
          duplicate: 0,
          rest: 0,
        };
      }
      obj[time].all += 1;
      if (click.isBot) {
        obj[time].bot += 1;
      } else if (click.isDuplicate) {
        obj[time].duplicate += 1;
      } else {
        obj[time].rest += 1;
      }
    });
    return Object.keys(obj).reduce((arr, time) => [
      ...arr,
      ...Object.keys(obj[time]).map((type) => ({
        time,
        type,
        // @ts-ignore
        value: obj[time][type],
      })),
    ], [] as LineData[]);
  }, [clicks]);

  const pieData: PieData[] = useMemo(() => {
    const data = {
      bot: 0,
      duplicate: 0,
      rest: 0,
    };

    clicks.forEach((click) => {
      if (click.isBot) {
        data.bot += 1;
      } else if (click.isDuplicate) {
        data.duplicate += 1;
      } else {
        data.rest += 1;
      }
    });

    // @ts-ignore
    return Object.keys(data).map((type) => ({ type, value: data[type] }));
  }, [clicks]);

  return (
    <PageLayout>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Form layout="inline" style={{ gap: 20 }}>
          <Form.Item label="Limit">
            <InputNumber
              type="number"
              value={queryLimit}
              onChange={setQueryLimit}
            />
          </Form.Item>
          <Form.Item label="IP">
            <Input
              value={userIp}
              placeholder="8.8.8.8"
              onChange={(event) => setUserIp(event.currentTarget.value)}
            />
          </Form.Item>
          <Form.Item label="Referrer">
            <Input
              value={referrer}
              placeholder="https://ecommerce-platforms.com/comparison-chart"
              onChange={(event) => setReferrer(event.currentTarget.value)}
            />
          </Form.Item>
          <Form.Item
            label="Pretty Link"
            validateStatus={prettyLinkSlug && !prettyLink ? 'error' : undefined}
            help={prettyLinkSlug && !prettyLink ? 'Link not found' : undefined}
          >
            <Input
              value={prettyLinkSlug}
              placeholder="go/tryShopify"
              onChange={(event) => setPrettyLinkSlug(event.currentTarget.value)}
            />
          </Form.Item>
        </Form>
        <Form layout="inline" style={{ gap: 20 }}>
          <Form.Item label="Auto refresh every min">
            <Switch
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={autoRefresh}
              onChange={setAutoRefresh}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              icon={<ReloadOutlined />}
              onClick={fetchClicks}
              loading={loading}
            >
              Refresh
            </Button>
          </Form.Item>
        </Form>
      </div>

      <Divider />

      <Spin spinning={loading || links.length === 0}>
        <Row>
          <Col span={16}>
            <LineChart data={lineData} />
          </Col>
          <Col span={8}>
            {pieData.length > 0 && <PieChart data={pieData} />}
          </Col>
        </Row>

        <Divider />

        <div style={{ overflowX: 'auto' }}>
          <Table
            dataSource={clicks}
            columns={columns}
            rowKey={(click) => click.id}
          />
        </div>
      </Spin>
    </PageLayout>
  );
}

export default RedirectEngineClicks;
