import React, { useEffect, useState } from 'react';
import { message, Table } from 'antd';
import {
  getFirestore, onSnapshot, DocumentReference, DocumentData,
} from 'firebase/firestore';
import { SavedTheme } from '../../types';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    sorter: (a: SavedTheme, b: SavedTheme) => a.name.localeCompare(b.name),
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
  },
  {
    title: 'Marketplace',
    dataIndex: 'marketplace',
    key: 'marketplace',
    filters: [
      {
        text: 'Shopify',
        value: 'Shopify',
      },
      {
        text: 'ThemeForest',
        value: 'ThemeForest',
      },
      {
        text: 'Other',
        value: 'Other',
      },
    ],
    onFilter: (value: string | number | boolean, record: SavedTheme) => {
      if (value === 'Other') {
        return !['Shopify', 'ThemeForest'].includes(record.marketplace || '');
      }
      return record.marketplace === value;
    },
  },
];

interface Props {
  docRef: DocumentReference<DocumentData>,
}

function ThemesTable({ docRef }: Props) {
  const firestore = getFirestore();
  const [loading, setLoading] = useState(true);
  const [themes, setThemes] = useState<SavedTheme[]>();

  useEffect(() => {
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      const data = docSnap.data() as { themes: SavedTheme[] } | undefined;
      setThemes(data?.themes || []);
      setLoading(false);
    }, (error) => {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! Something went wrong while fetching data from firebase.');
    });
    return unsubscribe;
  }, [docRef, firestore]);

  return (
    <Table
      loading={loading}
      dataSource={themes}
      columns={columns}
      rowKey={(theme) => theme.name}
    />
  );
}

export default ThemesTable;
