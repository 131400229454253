import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import './App.css';
import Login from './screens/Login';
import RedirectEngineClicks from './screens/RedirectEngineClicks';
import ThemeDetectorStats from './screens/ThemeDetectorStats';
import ThemeDetectorShopifyThemes from './screens/ThemeDetectorShopifyThemes';
import ThemeDetectorWordPressThemes from './screens/ThemeDetectorWordPressThemes';
import BusinessNameGeneratorStats from './screens/BusinessNameGeneratorStats';
import BusinessNameGeneratorKeywords from './screens/BusinessNameGeneratorKeywords';
import NotFound from './screens/NotFound';

function App() {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const auth = getAuth();
    return onAuthStateChanged(auth, (usr) => {
      setInitializing(false);
      setUser(usr);
    });
  }, []);

  if (!user) {
    return (
      <Login loading={initializing} />
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/redirect-engine/clicks" replace />} />

      <Route path="/redirect-engine" element={<Navigate to="/redirect-engine/clicks" replace />} />
      <Route path="/redirect-engine/clicks" element={<RedirectEngineClicks />} />

      <Route path="/theme-detector" element={<Navigate to="/theme-detector/stats" replace />} />
      <Route path="/theme-detector/stats" element={<ThemeDetectorStats />} />
      <Route path="/theme-detector/shopify-themes" element={<ThemeDetectorShopifyThemes />} />
      <Route path="/theme-detector/wordpress-themes" element={<ThemeDetectorWordPressThemes />} />

      <Route path="/business-name-generator" element={<Navigate to="/business-name-generator/stats" replace />} />
      <Route path="/business-name-generator/stats" element={<BusinessNameGeneratorStats />} />
      <Route path="/business-name-generator/keywords" element={<BusinessNameGeneratorKeywords />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
