import React, { useCallback, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm } from 'antd';
import { DocumentData, DocumentReference, deleteDoc } from 'firebase/firestore';

interface Props {
  docRef: DocumentReference<DocumentData>,
  buttonText: string,
  confirmMessage: string,
  successMessage: string,
  errorMessage: string,
}

function DocDeleteBtn({
  docRef,
  buttonText,
  confirmMessage,
  successMessage,
  errorMessage,
} : Props) {
  const [deleting, setDeleting] = useState(false);

  const handleDelete = useCallback(async () => {
    setDeleting(true);
    try {
      await deleteDoc(docRef);
      message.success(successMessage);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error(errorMessage);
    }
    setDeleting(false);
  }, [docRef, errorMessage, successMessage]);

  return (
    <Popconfirm
      title={confirmMessage}
      onConfirm={handleDelete}
      okText="Yes Delete"
      okButtonProps={{ danger: true }}
      cancelText="No"
    >
      <Button
        danger
        icon={<DeleteOutlined />}
        loading={deleting}
      >
        {buttonText}
      </Button>
    </Popconfirm>
  );
}

export default DocDeleteBtn;
