import React, { useCallback } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import { getAuth, signInWithRedirect, GoogleAuthProvider } from 'firebase/auth';

const Container = styled.div`
  display: flex;
  padding: max(20vh, 200px) 30px 60px;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

interface Props {
  // eslint-disable-next-line react/require-default-props
  loading?: boolean,
}

function Login({ loading = false }: Props) {
  const handleLogin = useCallback(() => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  }, []);

  return (
    <Container>
      <h1>EP Firebase Admin</h1>
      <Button onClick={handleLogin} type="primary" loading={loading}>Login with Google</Button>
    </Container>
  );
}

export default Login;
