import React, { useCallback, useState } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { stringify } from 'csv-stringify/browser/esm/sync';

interface Props {
  data: any[],
  columns: string[],
  exportFilename: string,
  // eslint-disable-next-line react/require-default-props
  buttonText?: string,
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean,
}

function CSVExportBtn({
  data, columns, exportFilename, buttonText, disabled,
}: Props) {
  const [exporting, setExporting] = useState(false);

  const handleExport = useCallback(() => {
    setExporting(true);
    try {
      const csvString = stringify(
        data,
        {
          columns,
          header: true,
          cast: { boolean: (bool: boolean) => bool.toString() },
        },
      );
      const blob = new Blob([csvString], { type: 'text/csv' });
      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = exportFilename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
      setExporting(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! Something went wrong while generating export file.');
      setExporting(false);
    }
  }, [columns, data, exportFilename]);

  return (
    <Button
      icon={<DownloadOutlined />}
      onClick={handleExport}
      loading={exporting}
      disabled={disabled}
    >
      {buttonText || 'Export'}
    </Button>
  );
}

export default CSVExportBtn;
