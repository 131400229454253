import { initializeApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyD4di2yyv49r7E4_xoEYQrR4SHLTPVVnwk',
  authDomain: 'ecommerce-platforms-firebase.firebaseapp.com',
  projectId: 'ecommerce-platforms-firebase',
  storageBucket: 'ecommerce-platforms-firebase.appspot.com',
  messagingSenderId: '316296464681',
  appId: '1:316296464681:web:0d92c9586e7ee206c90f38',
};

initializeApp(firebaseConfig);

if (process.env.NODE_ENV !== 'production') {
  connectFunctionsEmulator(getFunctions(), 'localhost', 5001);
}
