import React, { useCallback, useState } from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface Props {
  cms: 'Shopify' | 'WordPress',
}

function AutoIndexBtn({ cms }: Props) {
  const [indexing, setIndexing] = useState(false);

  const handleIndexNow = useCallback(() => {
    setIndexing(true);
    message.info('Started indexing in background. It shouldn\'t take more than a minute.');

    const functions = getFunctions();
    const indexThemes = httpsCallable(functions, `index${cms}Themes`);
    indexThemes()
      .then((result) => {
        const data = result.data as { totalIndexed: number };
        message.success(`Successfully indexed ${data.totalIndexed} themes.`);
      })
      .catch((error) => {
        const { message: errorMessage } = error as Error;
        message.error(errorMessage || `Oops! Something went wrong while indexing ${cms} themes.`);
      })
      .finally(() => {
        setIndexing(false);
      });
  }, [cms]);

  return (
    <Button
      type="primary"
      icon={<ReloadOutlined />}
      onClick={handleIndexNow}
      loading={indexing}
    >
      Index Again Now
    </Button>
  );
}

export default AutoIndexBtn;
