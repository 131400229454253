import React from 'react';
import PageLayout from '../components/PageLayout';

function BusinessNameGeneratorStats() {
  return (
    <PageLayout>
      <h1>TBA</h1>
    </PageLayout>
  );
}

export default BusinessNameGeneratorStats;
