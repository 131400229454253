import React from 'react';
import { Pie } from '@ant-design/plots';

export interface PieData {
  value: number,
  type: string,
}

interface Props {
  data: PieData[],
}

function PieChart({ data }: Props) {
  return (
    <Pie
      appendPadding={10}
      data={data}
      angleField="value"
      colorField="type"
      radius={0.9}
      label={{
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(1)}%`,
        style: {
          fontSize: 14,
          textAlign: 'center',
        },
      }}
      interactions={[
        {
          type: 'element-active',
        },
      ]}
    />
  );
}

export default PieChart;
