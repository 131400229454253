import React, { useCallback, useMemo } from 'react';
import {
  Breadcrumb,
  Button, Dropdown, Layout, Menu,
} from 'antd';
import {
  FileSearchOutlined, LogoutOutlined, RollbackOutlined, TranslationOutlined,
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import Avatar from 'antd/lib/avatar/avatar';
import { titleize } from '../utils';

const { SubMenu } = Menu;
const {
  Header, Content, Sider, Footer,
} = Layout;

interface Props {
  children: React.ReactNode,
}

function PageLayout({ children }: Props) {
  const navigate = useNavigate();
  const auth = getAuth();

  const handleUserMenuClick = useCallback(({ key: path }: { key: string }) => {
    switch (path) {
      case 'logout':
        auth.signOut();
        break;

      default:
        break;
    }
  }, [auth]);

  const handleMainMenuClick = useCallback(({ key: path }: { key: string }) => {
    navigate(path);
  }, [navigate]);

  const userMenu = useMemo(() => (
    <Menu onClick={handleUserMenuClick}>
      <Menu.Item key="logout" danger icon={<LogoutOutlined />}>Logout</Menu.Item>
    </Menu>
  ), [handleUserMenuClick]);

  return (
    <Layout>
      <Header style={{ display: 'flex', alignItems: 'center' }}>
        <Link to="/" style={{ color: '#fff', fontSize: '18px', fontWeight: '600' }}>EP Firebase Admin</Link>
        <div style={{ flex: '1 1 0%' }} />
        <Dropdown overlay={userMenu} trigger={['hover']}>
          <Button style={{ display: 'flex', alignItems: 'center', height: '32px' }} type="text">
            <Avatar src={auth.currentUser?.photoURL} size="small" />
            <span style={{ marginLeft: '10px', color: '#fff' }}>{auth.currentUser?.displayName}</span>
          </Button>
        </Dropdown>
      </Header>
      <Layout>
        <Sider width={280} style={{ background: '#fff' }}>
          <Menu
            mode="inline"
            defaultSelectedKeys={[window.location.pathname]}
            defaultOpenKeys={[window.location.pathname.split('/')[1]]}
            style={{ height: '100%', borderRight: 0 }}
            onClick={handleMainMenuClick}
          >
            <SubMenu key="redirect-engine" icon={<RollbackOutlined />} title="Redirect Engine">
              <Menu.Item key="/redirect-engine/clicks">Clicks</Menu.Item>
            </SubMenu>
            <SubMenu key="theme-detector" icon={<FileSearchOutlined />} title="Theme Detector">
              <Menu.Item key="/theme-detector/stats">Stats</Menu.Item>
              <Menu.Item key="/theme-detector/shopify-themes">Shopify Themes</Menu.Item>
              <Menu.Item key="/theme-detector/wordpress-themes">WordPress Themes</Menu.Item>
            </SubMenu>
            <SubMenu key="business-name-generator" icon={<TranslationOutlined />} title="Business Name Generator">
              <Menu.Item key="/business-name-generator/stats">Stats</Menu.Item>
              <Menu.Item key="/business-name-generator/keywords">Keywords</Menu.Item>
            </SubMenu>
          </Menu>
        </Sider>
        <Layout style={{ minHeight: 'calc(100vh - 64px)', padding: '0 24px' }}>
          <Breadcrumb style={{ margin: '16px 0' }}>
            {window.location.pathname.split('/').map((str) => (
              <Breadcrumb.Item key={str}>{titleize(str)}</Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <Content
            style={{
              padding: 24,
              margin: 0,
              background: '#fff',
            }}
          >
            {children}
          </Content>
          <Footer style={{ textAlign: 'center' }}>Developed with ❤️ in Jaipur</Footer>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default PageLayout;
