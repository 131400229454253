import React, {
  useEffect, useMemo, useState,
} from 'react';
import {
  message, Space, Table,
} from 'antd';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import PageLayout from '../components/PageLayout';
import CSVImportBtn from '../components/CSVImportBtn';
import CSVExportBtn from '../components/CSVExportBtn';
import DocDeleteBtn from '../components/DocDeleteBtn';

interface Keyword {
  keyword: string,
  position: 'BEFORE' | 'AFTER' | 'ANY',
  joinWithSpace: boolean,
}

const csvColumns = ['keyword', 'position', 'joinWithSpace'];

const columns = [{
  title: 'Keyword',
  dataIndex: 'keyword',
  key: 'keyword',
}, {
  title: 'Position',
  dataIndex: 'position',
  filters: [{
    text: 'BEFORE',
    value: 'BEFORE',
  }, {
    text: 'AFTER',
    value: 'AFTER',
  }, {
    text: 'ANY',
    value: 'ANY',
  }],
  onFilter: (value: string | boolean | number, keyword: Keyword) => value === keyword.position,
  key: 'position',
}, {
  title: 'Join with Space',
  dataIndex: 'joinWithSpace',
  key: 'joinWithSpace',
  filters: [{
    text: 'true',
    value: true,
  }, {
    text: 'false',
    value: false,
  }],
  onFilter: (value: string | boolean | number, keyword: Keyword) => value === keyword.joinWithSpace,
  render: (value: string | number | boolean) => value.toString(),
}];

function BusinessNameGeneratorKeywords() {
  const [loading, setLoading] = useState(true);
  const [keywords, setKeywords] = useState<Keyword[]>([]);

  const docRef = useMemo(() => {
    const firestore = getFirestore();
    return doc(firestore, 'business-name-generator/keywords');
  }, []);

  // fetch keywords on load
  useEffect(() => {
    const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data() as { keywords: Keyword[] } | undefined;
        setKeywords(data?.keywords || []);
      } else {
        setKeywords([]);
      }
      setLoading(false);
    }, (error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      setLoading(false);
      message.error('Oops, something went wrong while fetching data from firebase.');
    });
    return unsubscribe;
  }, [docRef]);

  return (
    <PageLayout>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Space>
          <CSVImportBtn
            itemName="keyword"
            docRef={docRef}
            columns={csvColumns}
            uniqueColumn="keyword"
            existingItems={keywords}
            sampleData={[
              { keyword: 'Place', position: 'ANY', joinWithSpace: true },
              { keyword: 'Our', position: 'BEFORE', joinWithSpace: true },
              { keyword: 'ly', position: 'AFTER', joinWithSpace: false },
            ]}
            fileFieldHelpText={`The CSV file should have three columns: "${csvColumns.join(', ')}" in the exact same order.`}
            itemValidator={(item: Keyword) => (
              typeof item.keyword === 'string'
              && item.keyword.length > 0
              && ['BEFORE', 'AFTER', 'ANY'].includes(item.position)
              && typeof item.joinWithSpace === 'boolean'
            )}
            formalDocData={(items: Keyword[]) => ({
              keywords: items,
            })}
          />
          <CSVExportBtn
            disabled={loading}
            data={keywords}
            columns={csvColumns}
            exportFilename={`business-name-generator-keywords-export-${new Date().toDateString()}.csv`}
          />
        </Space>
        <DocDeleteBtn
          docRef={docRef}
          buttonText="Delete All"
          confirmMessage="Are you sure you want to delete all keywords?"
          successMessage="Successfully deleted all keywords."
          errorMessage="Oops, something went wrong while deleting keywords. Pls try again."
        />
      </div>

      <br />

      <Table
        dataSource={keywords}
        columns={columns}
        loading={loading}
      />
    </PageLayout>
  );
}

export default BusinessNameGeneratorKeywords;
