import React from 'react';
import { Line } from '@ant-design/plots';

export interface LineData {
  time: string,
  value: number,
  type: string,
}

interface Props {
  data: LineData[],
}

function LineChart({ data }: Props) {
  return (
    <Line
      data={data}
      xField="time"
      yField="value"
      seriesField="type"
      smooth
      xAxis={{
        type: 'time',
        mask: 'YYYY-MM-DD hh:mm:ss a',
      }}
      yAxis={{
        label: {
          formatter: (v: string) => v.toLocaleString(),
        },
      }}
    />
  );
}

export default LineChart;
