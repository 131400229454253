import React, {
  ChangeEvent, useCallback, useState,
} from 'react';
import { UploadOutlined } from '@ant-design/icons';
import {
  Button, Checkbox, Divider, Form, Input, message, Modal,
} from 'antd';
import { parse } from 'csv-parse/browser/esm/sync';
import {
  DocumentData,
  DocumentReference,
  setDoc,
} from 'firebase/firestore';
import { titleize } from '../utils';
import CSVExportBtn from './CSVExportBtn';

interface Props<Item> {
  itemName: string,
  docRef: DocumentReference<DocumentData>,
  columns: string[],
  uniqueColumn: string,
  existingItems: Item[],
  sampleData: Item[],
  fileFieldHelpText: string,
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean,
  itemValidator: (item: Item) => boolean,
  formalDocData: (items: Item[]) => Record<string, unknown>,
}

function CSVImportBtn<Item>({
  itemName,
  docRef,
  columns,
  uniqueColumn,
  existingItems,
  sampleData,
  fileFieldHelpText,
  disabled,
  itemValidator,
  formalDocData,
}: Props<Item>) {
  const [fileInputKey, setFileInputKey] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [items, setItems] = useState<Item[]>([]);
  const [replaceExistingItems, setReplaceExistingItems] = useState(false);
  const [importing, setImporting] = useState(false);

  const resetState = useCallback(() => {
    setFileInputKey((key) => key + 1);
    setIsModalVisible(false);
    setItems([]);
    setReplaceExistingItems(false);
    setImporting(false);
  }, []);

  const handleFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setItems([]);

    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];

    file.text().then((text) => {
      let newItems = parse(text, {
        columns,
        fromLine: 2,
        cast: (val) => {
          if (val === 'true') {
            return true;
          } if (val === 'false') {
            return false;
          }
          return val;
        },
      }) as Item[];
      newItems = newItems.filter(itemValidator);
      if (newItems.length === 0) {
        message.error('Error: invalid or empty CSV file.');
      } else {
        setItems(newItems);
      }
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! Something went wrong while parsing the CSV file. Please make sure it\'s a valid CSV file.');
    });
  }, [columns, itemValidator]);

  const handleImport = useCallback(async () => {
    setImporting(true);
    try {
      await setDoc(
        docRef,
        formalDocData(replaceExistingItems ? items : [...existingItems, ...items]),
      );
      message.success(`Successfully imported ${items.length} ${itemName}(s).`);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      message.error('Oops! Something went wrong while saving new data into the database.');
    }
    resetState();
  }, [resetState, docRef, formalDocData, replaceExistingItems, items, existingItems, itemName]);

  return (
    <>
      <Button
        type="primary"
        icon={<UploadOutlined />}
        disabled={disabled}
        onClick={() => setIsModalVisible(true)}
      >
        Import
      </Button>
      <Modal
        title={`Import ${titleize(itemName)}s`}
        visible={isModalVisible}
        onOk={handleImport}
        okText={items.length > 0 ? `Import ${items.length} ${itemName}(s)` : 'Import'}
        okButtonProps={{
          disabled: items.length === 0,
        }}
        onCancel={resetState}
        confirmLoading={importing}
      >
        <Form.Item
          label="Select a CSV file to import"
          help={fileFieldHelpText}
        >
          <Input
            key={fileInputKey}
            type="file"
            accept=".csv"
            onChange={handleFileChange}
          />
        </Form.Item>
        {existingItems.length > 0 && (
        <Form.Item help={`Check this field if you want to replace existing ${itemName}s with the import data. Import will add new data otherwise.`}>
          <Checkbox
            checked={replaceExistingItems}
            onChange={(event) => setReplaceExistingItems(event.target.checked)}
          >
            {`Replace ${existingItems.length} existing ${itemName}s`}
          </Checkbox>
        </Form.Item>
        )}
        {items.length > 0 ? (
          <div style={{ marginTop: 20 }}>
            <p>{`${items.length} ${itemName}(s) will be imported. Showing ${items.length > 5 ? 'first 5' : 'all'} items for preview:`}</p>
            <div style={{ overflowX: 'auto' }}>
              <table style={{ width: '100%' }}>
                <thead className="ant-table-thead">
                  <tr>
                    {columns.map((column) => (
                      <th key={column} className="ant-table-cell">{titleize(column)}</th>
                    ))}
                  </tr>
                </thead>
                <tbody className="ant-table-tbody">
                  {items.slice(0, 5).map((item) => (
                    <tr
                      key={((item as unknown as Record<string, string | number | boolean>)[uniqueColumn]).toString() || ''}
                    >
                      {columns.map((column) => (
                        <td
                          className="ant-table-cell"
                        >
                          {((item as unknown as Record<string, string | number | boolean>)[column]).toString() || ''}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <>
            <Divider />
            <CSVExportBtn
              data={sampleData}
              columns={columns}
              exportFilename={`sample-${itemName}s.csv`}
              buttonText="Download sample file"
            />
          </>
        )}
      </Modal>
    </>
  );
}

export default CSVImportBtn;
